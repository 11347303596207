import React from "react";

import ReactDOM from "react-dom";

// state
import { Provider } from "react-redux";

import store from "./store";

// components
import App from "./App";

// styles
import "./assets/style/app.css";

import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
