import React, { useState } from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Introduction = () => {
  const [show, setShow] = useState(false)

  const scrollToPageBottom =()=>{
    setTimeout(() => {
      window.scroll({
        top: document.getElementById('root').clientHeight,
        behavior: 'smooth'
      });   
    }, 100);
  }

  return (
    <div className="introduction">
      <div className="section">
        {
          !show ?
            <div className="header" onClick={() => {setShow(true); scrollToPageBottom()}} >
              <div className="title"> What is Heartspots? </div>
              <FontAwesomeIcon icon={faAngleRight} className="user-circle-icon" />
            </div>
            :
            <>
              <div className="header expanded" onClick={() => setShow(false)} >
                <div className="title"> What is Heartspots? </div>
                <FontAwesomeIcon icon={faAngleDown} className="user-circle-icon" />
              </div>
              <div className="intro-one">
                A heartspot is your nearby place where Heartfulness meditation and rejuvenation practices are offered to the public free of charge by a certified trainer.
              </div>
              <div className="intro-one">
                Heartspots are found in most countries around the world and are open for training to all over legal age who have interest to learn the practices.
              </div>
              <div className="intro-three">
                No matter where you are, there is always a trainer available for you.
              </div>
              <div>
                In even the most hectic daily schedule, the Heartfulness App will help you to relax and unwind for a few minutes in the day, leading to a happier and more peaceful life.
              </div>
              <div className="intro-five">
                Relax – Meditate – Rejuvenate – Connect
              </div>
            </>
        }

      </div>
    </div>
  );
};

export default Introduction;
