// config
import config from '../../assets/data/site-config';

const Introduction = props => {
    return (
        <>
            <div className="introduction-FlexBox">
                <div className="Introduction-Box">
                    <h4 className="introduction-title"> What is Heartspots? </h4>
                    <div className="info-text-gotham heartspot-introduction-one">
                        A
                        &nbsp;<span className="gotham-medium">heartspot</span>&nbsp;
                        is your nearby place where
                        &nbsp;<span className="gotham-medium">Heartfulness</span>&nbsp;
                        meditation and rejuvenation practices are offered to the public free of charge by a certified trainer.
                    </div>
                    <div className="info-text-gotham heartspot-introduction-two">
                        &nbsp;<span className="gotham-medium">Heartspots</span>&nbsp;
                        are found in most countries around the world and are open for training to all over legal age who have interest to learn the practices.
                    </div>
                    <h5 className="heartspot-introduction-three"> No matter where you are, there is always a trainer available for you.</h5>

                    <div className="finalIntroduction-FlexBox">
                        <div className="introduction-AppLink-FlexBox">
                            <div className="info-text-gotham heartspot-final-introduction-one"> In even the most hectic daily schedule, the
                                &nbsp;<span className="gotham-medium"> Heartfulness App</span>&nbsp;
                                will help you to relax and unwind for a few minutes in the day, leading to a happier and more peaceful life.
                            </div>
                            <h6 className="heartspot-final-introduction-two">Relax – Meditate – Rejuvenate – Connect</h6>
                            <h6 className="heartspot-final-introduction-three">Now available on </h6>
                            <p>
                                <a href={config.heartsAppDownloadPlayStoreURL} target="_blank" rel="noreferrer">
                                    <img src="/assets/playStoreDownloadButton.png"
                                        width="130px"
                                        className="introduction-AppLink-Image"
                                        alt="Google Play Store link"
                                    />
                                </a>
                                <a href={config.heartsAppDownloadAppStoreURL} target="_blank" rel="noreferrer">
                                    <img src="/assets/appStoreDownloadButton.png"
                                        width="130px"
                                        className="introduction-AppLink-Image"
                                        alt="Application store link"
                                    />
                                </a>
                            </p>
                        </div>
                        {
                            props.showTrainersAndCenters &&
                            <div className="introduction-Mobile-Box">
                                <img
                                    src="/assets/heartsappMobile.png"
                                    className="introduction-Mobile-Image"
                                    alt="Introduction mobile"
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                !props.showTrainersAndCenters &&
                <div className="introduction-Mobile-Box">
                    <img
                        src="/assets/heartsappMobile.png"
                        className="introduction-Mobile-Image"
                        alt="Introduction mobile"
                    />
                </div>
            }
        </>
    )
}

export default Introduction;
