import { useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import TrainersAndCenters from "../../trainers-and-centers";
import SearchResultTabs from "../../trainers-and-centers/SearchResultTabs";
import Introduction from "../../introduction";
import Header from "../../header";
import MobileHeaderSidebar from "../../header/MobileHeaderSidebar";
import Footer from "../../footer";
import siteConfig from "../../../assets/data/site-config";

import { useLocation, useSearchParams } from "react-router-dom";
import {
  setActiveCenterTab,
  setLoader,
  setResults,
} from "../../../store/actions/actions";
import TrainersService from "../../../services/trainersService";
import CentersService from "../../../services/centersService";
import { toast } from "react-toastify";
const MAP_LIBRARIES = ["places"];
const searchDefaults = {
  trainersLimit: 100,
  centersLimit: 5,
  distanceUnit: "KM",
  minDistance: 25,
};

const trainersService = new TrainersService();
const centersService = new CentersService();
const DeepLinkHome = (props) => {
  const pathlocation = useLocation();
  const [searchParams] = useSearchParams();
  const [showTrainersAndCenters] = useState(
    searchParams.get("tab") === "trainer" ||
      searchParams.get("tab") === "center"
      ? true
      : false
  );
  const [showSideBar, setShowSideBar] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const searchLocations = (position) => {
      const place = {
        latitude: Number(searchParams.get("lat")),
        longitude: Number(searchParams.get("lon")),
        place_id: "",
        formatted_address: "",
        distance: searchDefaults.minDistance,
        distanceUnit: searchDefaults.distanceUnit,
        limit: searchDefaults.trainersLimit,
        centersLimit: searchDefaults.centersLimit,
      };
      dispatch(setLoader(true));
     const oneApiCall= searchParams.get("tab") === "trainer" ? trainersService.getTrainers(place) :centersService.getCenters(place)
        try {
          oneApiCall.then((res) => {
            searchParams.get("tab") === "trainer" ?  dispatch(setResults(res.data, {}, place)) :dispatch(setResults({},res.data, place));
            searchParams.get("tab") === "center" &&  dispatch(setActiveCenterTab(true));
            dispatch(setLoader(false));
          });
        } catch (err) {
          toast.error("Error. Something went wrong.");
          dispatch(setLoader(false));
        }
    };
    searchLocations()

    // if (navigator?.geolocation) {
    //   navigator.geolocation.getCurrentPosition(searchLocations);
    // }
  }, [dispatch, searchParams]);

  let googleMapsApiKey = "";

  if (siteConfig?.googleMapsApiKey) {
    googleMapsApiKey = siteConfig.googleMapsApiKey;
  }

  return (
    <LoadScript libraries={MAP_LIBRARIES} googleMapsApiKey={googleMapsApiKey}>
      {showSideBar ? (
        <div
          className="mobile-sticky-header-overlay active"
          onClick={() => setShowSideBar(false)}
        ></div>
      ) : (
        <div className="mobile-sticky-header-overlay"></div>
      )}
      <Header onOpenSideBar={setShowSideBar} />
      {showSideBar && <MobileHeaderSidebar onCloseSideBar={setShowSideBar} />}
      <SearchResultTabs
        deepLink={{ tab: searchParams.get("tab"), path: pathlocation.pathname }}
      />
      <div className="main-Section">
        <div
          className={
            pathlocation.pathname === "/deep"
              ? `main-Section-FlexBox-deeplink`
              : "main-Section-FlexBox"
          }
        >
          <div className="results-introduction-box">
            <div className="results-introduction">
              {showTrainersAndCenters && (
                <div
                  className={
                    pathlocation.pathname === "/deep"
                      ? `trainersAndCenters-Box-deeplink`
                      : "trainersAndCenters-Box"
                  }
                >
                  <TrainersAndCenters />
                </div>
              )}
              {pathlocation.pathname !== "/deep" && (
                <Introduction showTrainersAndCenters={showTrainersAndCenters} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </LoadScript>
  );
};

export default DeepLinkHome;
