import { heartspotBase } from './base';

class CentersService {
    getCenters (place, page = 0) {
        const config = {
            params: {
                latitude: place.latitude,
                longitude: place.longitude,
                distance: place.distance,
                limit: place.centersLimit,
                page: page,
                distanceUnit: place.distanceUnit
            }
        }
        
        return heartspotBase.get('/centers', config)
    }

}

export default CentersService;