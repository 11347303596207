import React, { useCallback } from "react";

// state
import { useSelector, useDispatch } from "react-redux";

import { setActiveCenterTab } from "../../../store/actions/actions";

const SearchResultTabs = (deepLink) => {
  const linkValues={...deepLink.deepLink}
  const [isActiveCenterTab, totalTrainerCount, totalCenterCount] = useSelector(state => ([
    state.home.isActiveCenterTab,
    state.home.trainerResults.totalCount,
    state.home.centerResults.totalCount
  ]));
  const dispatch = useDispatch();

  const onTabClick = useCallback(value => {
    dispatch(setActiveCenterTab(value));
  }, [dispatch]);

  return (<>
  {linkValues.path === "/m-deep"  ?
    <div className="search-tabs section">
      <div className="tab-list">
        <div className={linkValues.path === "/m-deep" ? "tab-deeplink" :"tab"} onClick={() => onTabClick(false)}>
        {linkValues.tab === "trainer" && <div className="tab-item">
            <div className={`tab-text ${isActiveCenterTab ? "" : "active"}`}>
              Trainers (
              <span> {totalTrainerCount} </span>
              )
            </div>
          </div>}
        </div>
        <div className={linkValues.path === "/m-deep" ? "tab-deeplink" :"tab"}  onClick={() => onTabClick(true)}>
        {linkValues.tab === "center" && <div className="tab-item">
            <div className={`tab-text ${isActiveCenterTab ? "active" : ""}`}>
              Centers (
              <span> {totalCenterCount} </span>
              )
            </div>
          </div>}
        </div>
      </div>
    </div>:
     <div className="search-tabs section">
     <div className="tab-list">
       <div className="tab" onClick={() => onTabClick(false)}>
         <div className="tab-item">
           <div className={`tab-text ${isActiveCenterTab ? "" : "active"}`}>
             Trainers (
             <span> {totalTrainerCount} </span>
             )
           </div>
         </div>
       </div>
       <div className="tab" onClick={() => onTabClick(true)}>
         <div className="tab-item">
           <div className={`tab-text ${isActiveCenterTab ? "active" : ""}`}>
             Centers (
             <span> {totalCenterCount} </span>
             )
           </div>
         </div>
       </div>
     </div>
   </div>}
    </>
  )
}

export default SearchResultTabs;
