import { LOADER, CLEAR_SEARCH, RESULT_TAB_CHANGE, SEARCH, TRAINERS_SEARCH, CENTERS_SEARCH } from "./actionTypes";

export const setLoader = (value) => ({
    type: LOADER,
    payload: value
})

export const clearSearch = () => ({
    type: CLEAR_SEARCH
})

export const setResults = (trainerResults, centerResults, place) => ({
    type: SEARCH,
    payload: {
        trainerResults: {
            totalCount: trainerResults.totalCount || 0,
            count: trainerResults.count || 0,
            hasMore: trainerResults.hasMore || false,
            page: trainerResults.page || 0,
            trainers: trainerResults.trainers || []
        },
        centerResults: {
            totalCount: centerResults.totalCount || 0,
            count: centerResults.count || 0,
            hasMore: centerResults.hasMore || false,
            page: centerResults.page || 0,
            meditationCenters: centerResults.meditationCenters || []
        },
        place: place
    }
})

export const setMoreTrainerResults = (result, prevResult) => ({
    type: TRAINERS_SEARCH,
    payload: {
        totalCount: result.totalCount || 0,
        count: prevResult.count + (result.count || 0),
        hasMore: result.hasMore || false,
        page: prevResult.page + 1,
        trainers: prevResult.trainers.concat(result.trainers || [])
    }
})

export const setMoreCenterResults = (result, prevResult) => ({
    type: CENTERS_SEARCH,
    payload: {
        totalCount: result.totalCount || 0,
        count: prevResult.count + (result.count || 0),
        hasMore: result.hasMore || false,
        page: prevResult.page + 1,
        meditationCenters: prevResult.meditationCenters.concat(result.meditationCenters || [])
    }
})

export const setActiveCenterTab = value => ({
    type: RESULT_TAB_CHANGE,
    payload: value
})
