import { useState} from "react";
import { LoadScript } from '@react-google-maps/api';
import SearchBar from '../../search-bar';
import TrainersAndCenters from '../../trainers-and-centers';
import SearchResultTabs from '../../trainers-and-centers/SearchResultTabs';
import Introduction from '../../introduction';
import Header from '../../header';
import MobileHeaderSidebar from '../../header/MobileHeaderSidebar';
import Footer from '../../footer';
import siteConfig from '../../../assets/data/site-config';

const MAP_LIBRARIES = ["places"];

const Home = () => {

  const [showTrainersAndCenters, setshowTrainersAndCenters] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  let googleMapsApiKey = '';

  if (siteConfig?.googleMapsApiKey) {
    googleMapsApiKey = siteConfig.googleMapsApiKey
  }

  return (
    <LoadScript
      libraries={MAP_LIBRARIES}
      googleMapsApiKey={googleMapsApiKey}
    >
      {
        showSideBar
          ?
          <div className="mobile-sticky-header-overlay active" onClick={() => setShowSideBar(false)}></div>
          :
          <div className="mobile-sticky-header-overlay"></div>
      }
      <Header onOpenSideBar={setShowSideBar} />
      {
        showSideBar &&
        <MobileHeaderSidebar onCloseSideBar={setShowSideBar} />
      }
      <div className="searchStrip-Section">
        <SearchBar onSearch={setshowTrainersAndCenters} />
      </div>
      <hr className="horizontal-line"/>
      <SearchResultTabs />
      <div className="main-Section">
        <div className="main-Section-FlexBox">
          <div className="results-introduction-box">
            <div className="results-introduction">
              {
                showTrainersAndCenters &&
                <div className="trainersAndCenters-Box">
                  <TrainersAndCenters />
                </div>
              }
              <Introduction showTrainersAndCenters={showTrainersAndCenters} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </LoadScript>
  )
}

export default Home;