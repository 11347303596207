import React, { useCallback } from "react";

// state
import { useDispatch, useSelector } from "react-redux";

import { clearSearch } from "../../../store/actions/actions";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";

const SearchQueryBar = () => {
  const place = useSelector(state => state.home.place);

  const dispatch = useDispatch();

  const clearResult = useCallback(() => {
    dispatch(clearSearch());
  }, [dispatch]);

  return (
    <div className="search-query">
      <div className="section-flex">
        <FontAwesomeIcon icon={faArrowAltCircleLeft} className="clear user-circle-icon" onClick={() => clearResult()} />
        <div className="location"> {place.formatted_address} </div>
        <div className="distance"> {`${place.distance} ${place.distanceUnit}`} </div>
      </div>
    </div>
  );
};

export default SearchQueryBar;
