// config
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import config from "../../assets/data/site-config";
import siteConfig from "../../assets/data/site-config";

const Trainer = (props) => {
  const mapValues = props.trainer;
  const pathlocation = useLocation();
  // get transformed and rounded distance start

  const mapLink = useMemo(() => {
    const lat =
      typeof mapValues.latitude === "number" ? mapValues.latitude : null;
    const lon =
      typeof mapValues.longitude === "number" ? mapValues.longitude : null;
    if (lat && lon) return `${config.googleMapURL}${lat},${lon}`;
  }, [mapValues]);

  const trainerMapLink = useMemo(() => {
    const lat =
      typeof mapValues.latitude === "number" ? mapValues.latitude : null;
    const lon =
      typeof mapValues.longitude === "number" ? mapValues.longitude : null;
    if (lat && lon) return `${config.googleMapURLDirect}${lat},${lon}`;
  }, [mapValues]);

  const getDistance = (distance) => {
    if (distance) {
      const parsedDistance = parseFloat(distance);
      if (!Number.isNaN(parsedDistance)) {
        const roundedDistance = Math.round(parsedDistance);
        if (roundedDistance < 1) return "<1";
        else return roundedDistance;
      } else return "<1";
    } else return "<1";
  };
  // get transformed and rounded distance end

  return (
    <div
      className={
        "trainer-box " +
        (props.trainer.id === siteConfig.globalGuideID
          ? "global-guide-trainer-box"
          : "")
      }
      onClick={() => props.openOrCloseForm(props.index)}
    >
      <div className="trainerAndCenter-BGImage">
        {props.trainer.photoUrl ? (
          <img
            src={props.trainer.photoUrl}
            height={55}
            width={55}
            alt="Trainer"
          />
        ) : (
          <div className="no-trainer-image"></div>
        )}
      </div>
      <div className="trainer-info-box">
        <div className="trainerInfo-Box">
          <h4 className="trainer-name">{props.trainer.name}</h4>
          {pathlocation.pathname === "/deep" && (
            <a
              className="center-directions"
              target="_blank"
              href={mapLink}
              rel="noreferrer"
            >
              Get Directions
            </a>
          )}
          {props.trainer.id === siteConfig.globalGuideID ? (
            <div className="trainer-role"> Global Guide </div>
          ) : (
            <></>
          )}
        </div>
        <div className="trainerMiles-Box">
          <span style={{float:"right"}}>
            {getDistance(props.trainer.distance)}&nbsp;{props.distanceUnit}
          </span>
          {(props.trainer.latitude && props.trainer.longitude) &&
            <>
              <br/>
              <span>
                <a className="trainer-form-show-map" href={trainerMapLink} rel="noreferrer" target="_blank">SHOW MAP &#8594;</a>
              </span>
            </> 
          }
        </div>

      </div>
    </div>
  );
};

export default Trainer;
