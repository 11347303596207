import { LOADER, CLEAR_SEARCH, RESULT_TAB_CHANGE, SEARCH, TRAINERS_SEARCH, CENTERS_SEARCH } from "../actions/actionTypes"

const initialState = {
    loader: false,
    isActiveCenterTab: false,
    place: {
        latitude: 0,
        longitude: 0,
        distance: 25,
        limit: 100,
        centersLimit: 50,
        page: 1,
        distanceUnit: "KM",
        location: ""
    },
    trainerResults: {
        totalCount: 0,
        count: 0,
        hasMore: false,
        page: 0,
        trainers: []
    },
    centerResults: {
        totalCount: 0,
        count: 0,
        hasMore: false,
        page: 0,
        meditationCenters: []
    }
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER:
            return { ...state, loader: action.payload };
        case CLEAR_SEARCH:
            return {
                ...state,
                place: initialState.place,
                trainerResults: initialState.trainerResults,
                centerResults: initialState.centerResults,
            };
        case RESULT_TAB_CHANGE:
            return { ...state, isActiveCenterTab: action.payload };
        case SEARCH:
            return {
                ...state,
                trainerResults: action.payload.trainerResults,
                centerResults: action.payload.centerResults,
                place: action.payload.place
            };
        case TRAINERS_SEARCH:
            return { ...state, trainerResults: action.payload };
        case CENTERS_SEARCH:
            return { ...state, centerResults: action.payload };
        default:
            return state;
    }
};

export default homeReducer;
