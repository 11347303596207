import { useSelector } from 'react-redux';

const Loader = () => {
    const loader = useSelector(state => state.home.loader);
    return (
        <>
        {
            loader 
            ?
            <div className="loader-wrapper">
                <div className="loader"></div>
            </div>
            :
            null
        }
        </>
    )
}
export default Loader;
