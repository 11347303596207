import React, { useEffect } from "react";

// state
//import { useSelector } from "react-redux";

// map
import { LoadScript } from "@react-google-maps/api";

// components

import SearchQueryBar from "../mobileView//SearchQueryBar";

import SearchResultTabs from "../mobileView//SearchResultTabs";

import TrainersResult from "../mobileView/TrainersResult";

import CentersResult from "../mobileView/CentersResult";

//import Introduction from "../mobileView//Introduction";

// config
import config from "../../../assets/data/site-config";

// state
import { useDispatch } from "react-redux";

import {
  setActiveCenterTab,
  setLoader,
  setResults,
} from "../../../store/actions/actions";

// toast
import { toast } from "react-toastify";

// services
import TrainersService from "../../../services/trainersService";

import CentersService from "../../../services/centersService";
import { useLocation, useSearchParams } from "react-router-dom";

// constants
const MAP_LIBRARIES = ["places"];

const searchDefaults = {
  trainersLimit: 100,
  centersLimit: 50,
  distanceUnit: "KM",
  minDistance: 25,
};
const trainersService = new TrainersService();
const centersService = new CentersService();

const MobileViewDeepIndex = () => {
  const pathlocation = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const searchLocations = (position) => {
      const place = {
        latitude: Number(searchParams.get("lat")),
        longitude: Number(searchParams.get("lon")),
        place_id: "",
        formatted_address: "",
        distance: searchDefaults.minDistance,
        distanceUnit: searchDefaults.distanceUnit,
        limit: searchDefaults.trainersLimit,
        centersLimit: searchDefaults.centersLimit,
      };
      dispatch(setLoader(true));
      const oneApiCall= searchParams.get("tab") === "trainer" ? trainersService.getTrainers(place) :centersService.getCenters(place)
      try {
        oneApiCall.then((res) => {
          searchParams.get("tab") === "trainer" ?  dispatch(setResults(res.data, {}, place)) :dispatch(setResults({},res.data, place));
          searchParams.get("tab") === "center" &&  dispatch(setActiveCenterTab(true));
          dispatch(setLoader(false));
        });
      } catch (err) {
        toast.error("Error. Something went wrong.");
        dispatch(setLoader(false));
      }
    };
    searchLocations()
    // if (navigator?.geolocation) {
    //   navigator.geolocation.getCurrentPosition(searchLocations);
    // }
  }, [dispatch, searchParams]);

  // const [hasResults] = useSelector((state) => {
  //   return [
  //     state.home.trainerResults.totalCount > 0 ||
  //       state.home.centerResults.totalCount > 0,
  //     state.home.isActiveCenterTab,
  //   ];
  // });
  return (
    <LoadScript
      libraries={MAP_LIBRARIES}
      googleMapsApiKey={config.googleMapsApiKey}
    >
      <div className="mv">
        {/* <div className={`search-bar ${hasResults ? "display-none" : ""}`}>
          <SearchBar />
        </div> */}
          <div className="search-info-section">
            <SearchQueryBar />
            <SearchResultTabs
              deepLink={{
                tab: searchParams.get("tab"),
                path: pathlocation.pathname,
              }}
            />
          </div>
          <div className="result">
            <div className="section">
              {searchParams.get("tab") === "center" && <CentersResult />}
              {searchParams.get("tab") === "trainer" && <TrainersResult />}
            </div>
          </div>
        {/* <Introduction showTrainersAndCenters={true} /> */}
      </div>
    </LoadScript>
  );
};
export default MobileViewDeepIndex;
