import React, { useMemo, useCallback } from "react";

// assets
import heartImgBlack from "../../assets/images/heartspots-black.png";

// config
import config from "../../assets/data/site-config";

const Center = ({ index, center, openOrCloseForm }) => {

  const onCenterClick = useCallback((e) => {
    if (e.target.className !== "center-directions")
      openOrCloseForm(index);
  }, [index, openOrCloseForm]);

  const centerAddress = useMemo(() => {
    let address = "";

    if (center.street) address += center.street;
    if (center.city) address ? address += ", " + center.city : address += center.city;
    if (center.state) address ? address += ", " + center.state : address += center.state;
    if (center.country) address ? address += ", " + center.country : address += center.country;

    return address || "-";
  }, [center]);

  
const floatToTime = (number) => {
  //Do something with the input
  var sign = (number >= 0) ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  var hour = Math.floor(number);
  var decpart = number - hour;

  var min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  var minute = Math.floor(decpart * 60) + '';

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute; 
    }

  // Add Sign in final result
  sign = sign === 1 ? '' : '-';

  // Concate hours and minutes
  var time = sign + hour + ':' + minute;

  return time;
};

  const centerTimings = useMemo(() => {
    let days = {
      sun: { day: "Sunday", timings: [] },
      mon: { day: "Monday", timings: [] },
      tue: { day: "Tuesday", timings: [] },
      wed: { day: "Wednesday", timings: [] },
      thu: { day: "Thursday", timings: [] },
      fri: { day: "Friday", timings: [] },
      sat: { day: "Saturday", timings: [] }
    };
    let timings = [];

    if (center.meditationTimings) {
      center.meditationTimings.forEach((value) => {
        if (value.active) {
          if (days[value.dayOfTheWeek]) {
            if (value.meditationTime) {

              let hour, minute, timePart;
            /*  const timeParts = value.meditationTime.toString().split(".");
              if (timeParts[0]) {
                const hourPart = parseInt(timeParts[0]);
                if (!isNaN(hourPart)) {
                  timePart = (hourPart < 12) ? "AM" : "PM";
                  if (hourPart === 0) {
                    hour = "00";
                  }
                  else if (hourPart > 12) {
                    hour = (hourPart - 12).toString();
                  }
                  else {
                    hour = hourPart.toString();
                  }

                  if (hour.length === 1) {
                    hour = "0" + hour;
                  }
                }
              }

              if (timeParts[1]) {
                const minutePart = parseInt(timeParts[1]);
                if (!isNaN(minutePart) && (minutePart > 0)) {
                  minute = ((60 * minutePart) / (timeParts[1].length)).toString();
                  if (minute.length === 1) {
                    minute = "0" + minute;
                  }
                  if (minute.length > 2) {
                    minute = minute.slice(0, 2);
                  }
                }
              }*/
              var minutesPart = floatToTime(value.meditationTime)
               var timeParts = minutesPart.toString().split(":");
               if (timeParts[0]) {
                const hourPart = parseInt(timeParts[0]);
                if (!isNaN(hourPart)) {
                  timePart = (hourPart < 12) ? "AM" : "PM";
                  if (hourPart === 0) {
                    hour = "00";
                  }
                  else if (hourPart > 12) {
                    hour = (hourPart - 12).toString();
                  }
                  else {
                    hour = hourPart.toString();
                  }

                  if (hour.length === 1) {
                    hour = "0" + hour;
                  }
                }
              }
              minute=  timeParts[1];

              if (hour) {
                if (!minute)
                  minute = "00";
                days[value.dayOfTheWeek].timings.push(hour + ":" + minute + " " + timePart);
              }
            }
          }
        }
      });

      Object.values(days).forEach(item => {
        if (item.timings.length)
          timings.push(item.day + ": " + item.timings.join(", "))
      });
    }

    return timings.length ? timings : null;
  }, [center]);

  const mapLink = useMemo(() => {
    const lat = typeof center.latitude === "number" ? center.latitude : null;
    const lon = typeof center.longitude === "number" ? center.longitude : null;
    if (lat && lon) return `${config.googleMapURL}${lat},${lon}`;
  }, [center]);

  return (
    <div className="center-box" onClick={(e) => onCenterClick(e)}>
      <div className="trainerAndCenter-BGImage" style={{ backgroundImage: `url(${heartImgBlack})` }}>
        <span className="centerIndex-Span">
          {index + 1}
        </span>
      </div>
      <div className="centerInfo-Box">
        <h5 className="centerName">{center.name}</h5>
        <span className="center-address">Address: {centerAddress}</span>
        <div className="center-timing">
          <div > {`Timing: ${centerTimings ? "" : "-"}`}</div>
          {
            centerTimings ? (
              <ul className="timing-list" >
                {centerTimings.map((item, timeIndex) => (<li key={timeIndex} >{item}</li>))}
              </ul>
            ) : null
          }
        </div>
        <p className="center-email">Email: {center.email || " - "}</p>
        <p className="center-phone">Phone: {center.phone || " - "}</p>
        {
          mapLink ?
            <a className="center-directions"
              target="_blank"
              href={mapLink}
              rel="noreferrer"
            >
              Get Directions
            </a> : null
        }
      </div>
    </div>
  )
}

export default Center;
