import {useState} from 'react';
import menuItems from '../../assets/data/primary-menu-items.json';

const MobileHeaderSidebar = props => {
    const [showSubMenu, setShowSubMenu] = useState(-1);
    return (
        <div className="mobile-header-sidebar">
            <div className="mobile-header-scrollbar-wrap">
                <div className="mobile-header-close-icon" onClick={()=>props.onCloseSideBar(false)}>
                    <div className="close-line-wrap">
                        </div>
                </div>
                <ul className="mobile-menu" role="navigation">
                {
                    menuItems && 
                    menuItems.map((menuItems, index) => {
                        return (
                            <li key={index} className="mobile-menu-item" role="presentation">
                                <a href={menuItems.url} data-level="1" target={(menuItems.subMenu && menuItems.subMenu.length > 0) ? "" : "_blank"} rel="noreferrer" role="menuitem">
                                    <span className="menu-item-text">
                                        <span className="menu-text">{menuItems.name}</span>
                                    </span>
                                    {
                                        (menuItems.subMenu && menuItems.subMenu.length > 0) &&
                                        (showSubMenu === index
                                        ?
                                        <i className="next-level-button active" onClick={()=>setShowSubMenu(-1)}></i>
                                        :
                                        <i className="next-level-button" onClick={()=>setShowSubMenu(index)}></i>)
                                    }
                                </a>
                                <ul className="mobile-sub-menu" role="menubar">
                                {
                                    showSubMenu === index && menuItems.subMenu && 
                                    menuItems.subMenu.map((subMenuItem, subMenuindex) => {
                                        return (
                                            <li key={subMenuindex} role="presentation">
                                                <a href={subMenuItem.url} data-level="2" target="_blank" rel="noreferrer" role="menuitem">
                                                    <span className="menu-text">{subMenuItem.name}</span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default MobileHeaderSidebar;