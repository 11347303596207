import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

// config
import config from "../../../assets/data/site-config";

const Trainer = (props) => {
  const pathlocation = useLocation();
  const mapValues = props.trainer;
  const mapLink = useMemo(() => {
    const lat =
      typeof mapValues.latitude === "number" ? mapValues.latitude : null;
    const lon =
      typeof mapValues.longitude === "number" ? mapValues.longitude : null;
    if (lat && lon) return `${config.googleMapURL}${lat},${lon}`;
  }, [mapValues]);
  const distance = useMemo(() => {
    if (props.trainer.distance) {
      const parsedDistance = parseFloat(props.trainer.distance);
      if (!Number.isNaN(parsedDistance)) {
        const roundedDistance = Math.round(parsedDistance);
        if (roundedDistance < 1) return "<1";
        else return roundedDistance;
      } else return "<1";
    } else return "<1";
  }, [props.trainer.distance]);

  return (
    <div
      className={
        "trainer-bx " +
        (props.trainer.id === config.globalGuideID
          ? "global-guide-trainer-box"
          : "")
      }
      onClick={() => props.openOrCloseForm(props.index)}
    >
      <div className="image">
        {props.trainer.photoUrl ? (
          <img
            src={props.trainer.photoUrl}
            height={55}
            width={55}
            alt="Trainer"
          />
        ) : (
          <div className="empty-image"></div>
        )}
      </div>
      <div className="info-box">
        <div className="name-box">
          <div className="name">{props.trainer.name}</div>
          {pathlocation.pathname === "/m-deep" && (
            <a
              className="center-directions"
              target="_blank"
              href={mapLink}
              rel="noreferrer"
            >
              Get Directions
            </a>
          )}
          {props.trainer.id === config.globalGuideID ? (
            <div className="role"> Global Guide </div>
          ) : (
            <></>
          )}
        </div>
        <div className="distance">
          {distance}&nbsp;{props.distanceUnit}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
