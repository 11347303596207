import React, { useMemo, useCallback } from "react";

// state
import { useSelector, useDispatch } from "react-redux";

import { setLoader } from "../../../store/actions/actions";

// form
import { useForm, Controller } from "react-hook-form";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUserCircle, faEnvelope, faPhoneAlt, faGlobe, faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

// toast
import { toast } from "react-toastify";

// recaptcha
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// components
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

// services
import FormService from "../../../services/formService";

// config
import config from "../../../assets/data/site-config";

// constants
const validators = {
  name: {
    required: {
      value: true,
      message: "Name should not be empty"
    },
    pattern: {
      value: /^(?![\s.]+$)[a-zA-Z\s]*$/,
      message: "Entered name has invalid characters"
    }
  },
  email: {
    required: {
      value: true,
      message: "Email should not be empty"
    },
    pattern: {
      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
      message: "Invalid Email"
    }
  },
  mobile: {
    required: false,
    maxLength: {
      value: 16,
      message: "Enter a valid phone number"
    },
    pattern: {
      value: /^[+]?[0-9]{0,15}$/,
      message: "Enter a valid phone number"
    },
    validate: value => { return ((value && isPossiblePhoneNumber(value)) || !value) || "Enter a valid phone number" }
  },
  location: {
    required: false
  },
  message: {
    required: false
  },
  terms: {
    required: {
      value: true,
      message: "Please agree to the terms and conditions"
    },
  },
};

const ContactForm = props => {

  const { register, handleSubmit, formState: { errors }, control } = useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const place = useSelector(state => state.home.place);

  const dispatch = useDispatch();

  const formService = useMemo(() => new FormService(), []);

  const onSubmit = useCallback(data => {
    dispatch(setLoader(true));

    executeRecaptcha()
      .then(token => {
        let trainerId = null;
        let centerId = null;

        if (props.trainer) {
          if (props.trainer.id === config.globalGuideID)
            centerId = config.globalGuideCenterID;
          else
            trainerId = props.trainer.id;
        }
        else if (props.center)
          centerId = props.center.id;

        if (data.mobile === undefined)
          data.mobile = "";

        formService.submit(data, token, trainerId, centerId, place)
          .then(() => {
            props.CloseForm();
            toast.success("Your message has been sent. After verification by Heartspots team you will be notified.")
          })
          .catch((e) => {
            if (e.response)
              toast.error(e.response.data.message || "Error. Something went wrong. Make sure you have proper internet connection")
            else
              toast.error("Error. Something went wrong. Make sure you have proper internet connection")
          })
          .then(() => dispatch(setLoader(false)));
      })
      .catch(() => {
        toast.error("Error in captcha");
        dispatch(setLoader(false));
      });
  }, [dispatch, formService, executeRecaptcha, props, place]);

  return (
    <div className="form">
      <div className="form-title">
        {(props.trainer && (props.trainer.id === config.globalGuideID)) ?
          <>
            <div className="global-guide-form-title">
              Daaji is the Heartfulness Guide, and he conducts live meditation sessions through the&nbsp;
              <a href="https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg" rel="noreferrer" target="_blank">Heartfulness YouTube</a>
              &nbsp;channel.
            </div>
            <div className="global-guide-form-title">Your message will be sent to a certified Heartfulness trainer who will connect with you for a 1-to-1 session.</div>

          </>
          :
          null
        }
        <span>SEND MESSAGE</span>, your message will be sent after verification by Heartspots team

      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-box">
          <FontAwesomeIcon icon={faUserCircle} className="user-circle-icon" />
          <div className="input-field">
            <input type="text" className="input-text-field"
              name="name"
              placeholder="Your Name"
              aria-invalid={errors.name ? "true" : "false"} {...register("name", validators.name)} />
            {errors.name && (<div role="alert" className="input-error"> {errors.name.message} </div>)}
          </div>
        </div>
        <div className="input-box">
          <FontAwesomeIcon icon={faEnvelope} className="user-circle-icon" />
          <div className="input-field">
            <input type="text" className="input-text-field"
              placeholder="Email Id"
              name="email"
              aria-invalid={errors.email ? "true" : "false"} {...register("email", validators.email)} />
            {errors.email && (<div role="alert" className="input-error"> {errors.email.message} </div>)}
          </div>
        </div>
        <div className="input-box">
          <FontAwesomeIcon icon={faPhoneAlt} className="user-circle-icon" />
          <div className="input-field">
            <Controller name="mobile" control={control} rules={validators.mobile} defaultValue="" render={props => {
              return <PhoneInput
                className="input-text-field"
                placeholder="Mobile No"
                defaultCountry="IN"
                aria-invalid={errors.mobile ? "true" : "false"}
                value={props.field.value}
                name={props.field.name}
                onChange={props.field.onChange}
              />
            }}
            />
            {errors.mobile && (<div role="alert" className="input-error"> {errors.mobile.message} </div>)}
          </div>
        </div>
        <div className="input-box">
          <FontAwesomeIcon icon={faGlobe} className="user-circle-icon" />
          <div className="input-field">
            <input type="text" className="input-text-field"
              placeholder="Location"
              name="location"
              aria-invalid={errors.location ? "true" : "false"} {...register("location", validators.location)} />
            {errors.location && (<div role="alert" className="input-error"> {errors.location.message} </div>)}
          </div>
        </div>
        <div className="input-box">
          <FontAwesomeIcon icon={faEnvelopeSquare} className="user-circle-icon" />
          <div className="input-field">
            <textarea rows={8} type="text"
              className="input-text-field"
              name="message"
              placeholder="Your Message"
              aria-invalid={errors.message ? "true" : "false"} {...register("message", validators.message)} />
            {errors.message && (<div role="alert" className="input-error"> {errors.message.message} </div>)}
          </div>
        </div>
        <div className="input-checkbox">
          <input
            type="checkbox"
            className="input-checkbox-icon"
            name="terms"
            aria-invalid={errors.terms ? "true" : "false"}
            {...register("terms", validators.terms)}
          />
          <div>
            <div className="input-checkbox-label">
              I Agree to the&nbsp;
              <a href={config.heartfulnessTermsURL} rel="noreferrer" target="_blank">Terms &amp; Conditions</a>
            </div>
            {errors.terms && (<div role="alert" className="input-terms-error"> {errors.terms.message} </div>)}
          </div>
        </div>
        <button className="submit-button" type="submit"> Submit </button>
      </form>
    </div>
  );
};

export default ContactForm;
