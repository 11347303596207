import { heartspotBase } from './base';

const CONTACT_TYPE = "ENQUIRY";

class FormService {
    submit (data, reCaptcha, trainerId, centerId, place) {
        const requestData = {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            contactType: CONTACT_TYPE,
            assignedPartner: trainerId,
            meditationCenter: centerId,
            formattedAddress: place.formatted_address,
            placeId: place.place_id,
            reCaptcha: reCaptcha,
            message: data.message
        }
        
        return heartspotBase.post('/contact-us', requestData)
    }

}

export default FormService;