const configData = process?.env;
let config = {};

try {
  config.googleMapsApiKey = configData.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyCIOT1Fg9FVXQR7ib3sVud9LH3w5L14rOI";
  config.captchaKey = configData.REACT_APP_GOOGLE_RECAPTCHA_KEY || "6LexkuoaAAAAAOdYHpK8VV75i0KGzFLdhEpVgZru";
  config.heartspotBaseURI = configData.REACT_APP_HEARTSPOT_API_BASE_URI || "https://location-service-grpc.qa.heartfulnessinstitute.in";
  config.globalGuideID = configData.REACT_APP_GLOBAL_GUIDE_ID || 188121;
  config.globalGuideCenterID = configData.REACT_APP_GLOBAL_GUIDE_CENTER_ID || 14;
}
catch {
  config.googleMapsApiKey = "AIzaSyCIOT1Fg9FVXQR7ib3sVud9LH3w5L14rOI";
  config.captchaKey = "6LexkuoaAAAAAOdYHpK8VV75i0KGzFLdhEpVgZru";
  config.heartspotBaseURI = "https://location-service-grpc.qa.heartfulnessinstitute.in";
  config.globalGuideID = 188121;
  config.globalGuideCenterID = 14;
}

config.heartfulnessHomeURL = "https://heartfulness.org/en/";
config.heartfulnessTermsURL = "https://heartfulness.org/en/terms";
config.heartsAppDownloadPlayStoreURL = "https://heartfulness.app.link/dBLbxGr4Dxb";
config.heartsAppDownloadAppStoreURL = "https://heartfulness.app.link/dBLbxGr4Dxb";
config.globalGuideYouTubeChannelURL = "https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg";
config.globalGuideYouTubeChannelURL = "https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg";
config.googleMapURL = "https://www.google.com/maps/dir/?api=1&destination=";
config.googleMapURLDirect ="http://maps.google.com/?q="

export default config;
