import { heartspotBase } from './base';

class TrainersService {
    getTrainers (place, page = 0) {
        const config = {
            params: {
                latitude: place.latitude,
                longitude: place.longitude,
                distance: place.distance,
                limit: place.limit,
                page: page,
                distanceUnit: place.distanceUnit
            }
        }

        return heartspotBase.get('/trainers', config)
    }

}

export default TrainersService;