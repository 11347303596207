import React, { useState, useMemo, useCallback } from "react";

// state
import { useSelector, useDispatch } from "react-redux";

import { setLoader, setMoreCenterResults } from "../../../store/actions/actions";

// toast
import { toast } from "react-toastify";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// components
import Center from "./Center";

import ContactForm from "./ContactForm";

// services
import CentersService from "../../../services/centersService";

const CentersResult = () => {
  const [formVisibility, setFormVisibility] = useState({ index: -1, visibility: false });

  const [centerResults, place] = useSelector(state => [state.home.centerResults, state.home.place]);

  const dispatch = useDispatch();

  const centersService = useMemo(() => new CentersService(), []);

  const moreCount = useMemo(() => {
    if (Number.isInteger(centerResults.totalCount) && (centerResults.totalCount > centerResults.meditationCenters.length))
      return centerResults.totalCount - centerResults.meditationCenters.length;
    return "";
  }, [centerResults]);

  const openOrCloseForm = useCallback((value = -1) => {
    if ((value === -1) || (value === formVisibility.index))
      setFormVisibility({ index: -1, visibility: false });
    else
      setFormVisibility({ index: value, visibility: true });
  }, [formVisibility.index]);

  const loadMoreCenters = useCallback(() => {
    dispatch(setLoader(true));

    centersService.getCenters(place, centerResults.page + 1)
      .then(res => dispatch(setMoreCenterResults(res.data, centerResults)))
      .catch(err => toast.error("Error. Something went wrong. Make sure you have proper internet connection"))
      .then(() => dispatch(setLoader(false)));
  }, [dispatch, centersService, place, centerResults]);

  return (
    <div className="list">
      {
        (centerResults.meditationCenters.length > 0)
          ?
          centerResults.meditationCenters.map((center, index) =>
            <div key={center.id} className="center-flex">
              <Center index={index} center={center} openOrCloseForm={openOrCloseForm} />
              {
                (formVisibility.visibility && (formVisibility.index === index)) &&
                <ContactForm center={center} CloseForm={() => openOrCloseForm()} />
              }
            </div>
          )
          :
          <div className="list-empty">No Data Found.</div>
      }

      {centerResults.hasMore ?
        <div className="more-box" onClick={loadMoreCenters}>
          <FontAwesomeIcon icon={faAngleDown} className="user-circle-icon" />
          <div className="more-text">
            {moreCount}&nbsp;more centers
          </div>
        </div>
        : null
      }
    </div>
  );
};

export default CentersResult;
