import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import Trainer from './Trainer';
import ContactForm from './ContactForm';
import { setLoader, setMoreTrainerResults } from '../../store/actions/actions';
import TrainersService from '../../services/trainersService';

const TrainersResult = () => {
    const [FormVisibility, setFormVisibility] = useState({ index: -1, visibility: false});
    const [trainerResults, place] = useSelector(state=>[state.home.trainerResults, state.home.place]);
    const dispatch = useDispatch();

    const trainersService = new TrainersService();

    const openOrCloseForm = (value = -1) => {
        if ( (value === -1) || (value === FormVisibility.index))
        {
            setFormVisibility({ index: -1, visibility: false})
        }
        else
        {
            setFormVisibility({ index: value, visibility: true})
        }
    };
    
    const loadMoreTrainers = () => {
        dispatch(setLoader(true))
        trainersService.getTrainers(place, trainerResults.page + 1)
            .then(res => dispatch(setMoreTrainerResults(res.data, trainerResults)) )
            .catch(err => toast.error("Error. Something went wrong.") )
            .then(() => dispatch(setLoader(false)) )
    }

    return (
        <>
            <h3 className="trainer-result-title"><span>SELECT THE TRAINER&nbsp;</span>you wish</h3>
            <div className="trainers-list">
            { 
                (trainerResults.trainers.length > 0) ?
                trainerResults.trainers.map((trainer, index) =>
                <div key={trainer.id} id={"trainer_" + trainer.id} className="trainer-FlexBox">
                    <Trainer
                        index={index} 
                        trainer={trainer} 
                        openOrCloseForm={openOrCloseForm}
                        distanceUnit={place.distanceUnit}
                    />
                    {
                        (FormVisibility.visibility && (FormVisibility.index === index)) && 
                        <ContactForm trainer={trainer} CloseForm={()=>openOrCloseForm()}/>
                    }
                </div>
                )
                :
                <h5 className="trainer-list-empty">No Data Found.</h5>
            } 
            { trainerResults.hasMore &&
                <div className="more-button-box">
                    <button className="more-button" onClick={loadMoreTrainers}>More</button>
                </div>
            }
            </div>
        </>
    )
}

export default TrainersResult;