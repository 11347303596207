import {useState} from 'react';
import heartfulnessLogo from '../../assets/images/logo.png';
import menuItems from '../../assets/data/primary-menu-items.json';

// config
import config from '../../assets/data/site-config';

const Header = props => {
    const [showSubMenu, setShowSubMenu] = useState(-1);
    return (
        <div className="header-section">
            <div className="header-bar">
                <div className="heartfulness-branding">
                    <a href={config.heartfulnessHomeURL} className="heartfulness-site-url" target="_blank" rel="noreferrer">
                        <img src={heartfulnessLogo} className="heartfulness-logo-svg" alt="heartfulness logo"/>
                    </a>
                </div>
                <ul className="primary-menu" role="menubar">
                    {
                        menuItems && 
                        menuItems.map((menuItems, index) => {
                            let subMenuStyle = {opacity: 0,visibility: 'hidden'};
                            let activeMenu = "";
                            
                            if (index === showSubMenu)
                            {
                                subMenuStyle = {opacity: 1,visibility: 'visible'}
                                if(menuItems.subMenu)
                                    activeMenu = " primary-menu-active";
                            }

                            return (
                                <li key={index}
                                className="primary-menu-item"
                                role="presentation"
                                onMouseLeave={()=>setShowSubMenu(-1)}
                                >
                                    <a href={menuItems.url}
                                    data-level="1" 
                                    role="menuitem" 
                                    target={(menuItems.subMenu && menuItems.subMenu.length > 0) ? "" : "_blank"} 
                                    rel="noreferrer"
                                    onMouseOver={()=>setShowSubMenu(index)}
                                    >
                                        <span className="menu-item-text">
                                            <span className={"menu-text"+activeMenu}>{menuItems.name}</span>
                                            <i className="underline"></i>
                                        </span>
                                    </a>
                                    <ul className="primary-sub-menu" role="menubar" style={subMenuStyle}>
                                    {
                                        menuItems.subMenu && 
                                        menuItems.subMenu.map((subMenuItem, subMenuindex) => {
                                            return (
                                                    <li key={subMenuindex} className="primary-sub-menu-item" role="presentation">
                                                        <a href={subMenuItem.url} data-level="2" target="_blank" rel="noreferrer" role="menuitem">
                                                            <span className="menu-text">{subMenuItem.name}</span>
                                                        </a>
                                                    </li>
                                            )
                                        })
                                    }
                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="mobile-header-bar">
                <div className="mobile-navigation" onClick={()=>props.onOpenSideBar(true)}>
                <div href="#" className="dt-mobile-menu-icon" >
                    <div className="lines-button ">
                        <span className="menu-line">
                        </span>
                        <span className="menu-line">
                        </span>
                        <span className="menu-line">
                        </span>
                    </div>
                </div>
                </div>
                <div className="mobile-mini-widgets"></div>
                <div className="heartfulness-mobile-branding">
                    <a href={config.heartfulnessHomeURL} target="_blank" rel="noreferrer">
                        <img src={heartfulnessLogo} className="heartfulness-logo-svg" alt="heartfulness logo"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header;
