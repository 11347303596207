import React, { useState, useMemo, useCallback } from "react";

// state
import { useSelector, useDispatch } from "react-redux";

import { setLoader, setMoreTrainerResults } from "../../../store/actions/actions";

// toast
import { toast } from "react-toastify";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// components
import Trainer from "./Trainer";

import ContactForm from "./ContactForm";

// services
import TrainersService from "../../../services/trainersService";

const TrainersResult = () => {
  const [formVisibility, setFormVisibility] = useState({ index: -1, visibility: false });

  const [trainerResults, place] = useSelector(state => [state.home.trainerResults, state.home.place]);

  const dispatch = useDispatch();

  const trainersService = useMemo(() => new TrainersService(), []);

  const moreCount = useMemo(() => {
    if (Number.isInteger(trainerResults.totalCount) && (trainerResults.totalCount > trainerResults.trainers.length)) {
      return trainerResults.totalCount - trainerResults.trainers.length;
    }
    return "";
  }, [trainerResults]);

  const openOrCloseForm = useCallback((value = -1) => {
    if ((value === -1) || (value === formVisibility.index))
      setFormVisibility({ index: -1, visibility: false });
    else
      setFormVisibility({ index: value, visibility: true });
  }, [formVisibility.index]);

  const loadMoreTrainers = useCallback(() => {
    dispatch(setLoader(true));

    trainersService.getTrainers(place, trainerResults.page + 1)
      .then(res => dispatch(setMoreTrainerResults(res.data, trainerResults)))
      .catch(() => toast.error("Error. Something went wrong. Make sure you have proper internet connection"))
      .then(() => dispatch(setLoader(false)));
  }, [dispatch, trainersService, place, trainerResults]);

  return (
    <div className="list">
      {
        (trainerResults.trainers.length > 0) ?
          trainerResults.trainers.map((trainer, index) =>
            <div key={trainer.id} className="trainer-flex">
              <Trainer
                index={index}
                trainer={trainer}
                openOrCloseForm={openOrCloseForm}
                distanceUnit={place.distanceUnit}
              />
              {
                (formVisibility.visibility && (formVisibility.index === index)) &&
                <ContactForm trainer={trainer} CloseForm={() => openOrCloseForm()} />
              }
            </div>
          )
          :
          <div className="list-empty">No Data Found.</div>
      }
      {trainerResults.hasMore ?
        <div className="more-box" onClick={loadMoreTrainers}>
          <FontAwesomeIcon icon={faAngleDown} className="user-circle-icon" />
          <div className="more-text">
            {moreCount}&nbsp;more trainers
          </div>
        </div>
        : null
      }
    </div>
  );
};

export default TrainersResult;
