import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Center from './Center';
import ContactForm from './ContactForm';
import { setLoader, setMoreCenterResults } from '../../store/actions/actions';
import CentersService from '../../services/centersService';

const CentersResult = () => {
    const [FormVisibility, setFormVisibility] = useState({ index: -1, visibility: false});
    const [centerResults, place] = useSelector(state=>[state.home.centerResults, state.home.place]);
    const dispatch = useDispatch();

    const centersService = new CentersService();

    const openOrCloseForm = (value = -1) => {
        if ( (value === -1) || (value === FormVisibility.index))
        {
            setFormVisibility({ index: -1, visibility: false})
        }
        else
        {
            setFormVisibility({ index: value, visibility: true})
        }
    };

    const loadMoreCenters = () => {
        dispatch(setLoader(true))
        centersService.getCenters(place, centerResults.page + 1)
            .then(res => dispatch(setMoreCenterResults(res.data, centerResults)) )
            .catch(err => toast.error("Error. Something went wrong.") )
            .then(() => dispatch(setLoader(false)) )
    }

    return (
        <>
            <h3 className="center-result-title">SELECT THE CENTER</h3>
            <div className="centers-list">
                {
                    (centerResults.meditationCenters.length > 0)
                    ?
                    centerResults.meditationCenters.map((center, index) =>
                    <div key={center.id} id={"center_" + center.id} className="center-FlexBox">
                        <Center index={index} center={center} openOrCloseForm={openOrCloseForm}/>
                        {
                            (FormVisibility.visibility && (FormVisibility.index === index)) && 
                            <ContactForm center={center} CloseForm={()=>openOrCloseForm()}/>
                        }
                    </div>
                    )
                    :
                    <h5 className="center-list-empty">No Data Found.</h5>
                }
                    
                {
                    centerResults.hasMore &&
                    <div className="more-button-box">
                        <button className="more-button" onClick={loadMoreCenters}>More</button>
                    </div>
                }
            </div>
        </>
    )
}

export default CentersResult;