import {useSelector} from 'react-redux';
import TrainersResult from './TrainersResult';
import CentersResult from './CentersResult';

const TrainersAndCenters = () => {
    const isActiveCenterTab = useSelector(state=>state.home.isActiveCenterTab);
    return (
        <div>
            {
                !isActiveCenterTab 
                ?
                <TrainersResult/>
                :
                <CentersResult/>
            }
        </div>
    )
}
export default TrainersAndCenters;