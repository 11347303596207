import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faEnvelope, faPhoneAlt, faGlobe, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { setLoader } from '../../store/actions/actions';
import FormService from '../../services/formService';

// config
import config from '../../assets/data/site-config';

const ContactForm = props => {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const place = useSelector(state => state.home.place);
    const dispatch = useDispatch();

    const formService = new FormService();

    const validators = {
        name: {
            required: {
                value: true,
                message: 'Name should not be empty'
            },
            pattern: {
                value: /^(?![\s.]+$)[a-zA-Z\s]*$/,
                message: 'Entered name has invalid characters'
            }
        },
        email: {
            required: {
                value: true,
                message: 'Email should not be empty'
            },
            pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: 'Invalid Email'
            }
        },
        mobile: {
            required: false,
            maxLength: {
                value: 16,
                message: 'Enter a valid phone number'
            },
            pattern: {
                value: /^[+]?[0-9]{0,15}$/,
                message: 'Enter a valid phone number'
            },
            validate: value => { return ((value && isPossiblePhoneNumber(value)) || !value) || 'Enter a valid phone number' }
        },
        location: {
            required: false
        },
        message: {
            required: false
        },
        terms: {
            required: {
                value: true,
                message: 'Please agree to the terms and conditions'
            },
        },
    }

    const onSubmit = (data) => {
        dispatch(setLoader(true))
        executeRecaptcha()
            .then(token => {
                let trainerId = null;
                let centerId = null;

                if (props.trainer) {
                    if (props.trainer.id === config.globalGuideID)
                        centerId = config.globalGuideCenterID;
                    else
                        trainerId = props.trainer.id;
                }
                else if (props.center) {
                    centerId = props.center.id;
                }

                if (data.mobile === undefined) {
                    data.mobile = '';
                }

                formService.submit(data, token, trainerId, centerId, place)
                    .then(() => {
                        props.CloseForm();
                        toast.success("Request has been submitted successfully.")
                    })
                    .catch((e) => {
                        if (e.response)
                            toast.error(e.response.data.message || "Error. Something went wrong.")
                        else
                            toast.error("Error. Something went wrong.")
                    })
                    .then(() => dispatch(setLoader(false)))
            })
            .catch(() => {
                toast.error("Error in captcha")
                dispatch(setLoader(false))
            })
    }

    return (
        <div className="trainer-form">
            <h3 className="trainer-form-title">
                {
                    (props.trainer && (props.trainer.id === config.globalGuideID))
                        ?
                        <>
                            <div className="global-guide-form-title">
                                Daaji is the Heartfulness Guide, and he conducts live meditation sessions through the&nbsp;
                                <a href="https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg" rel="noreferrer" target="_blank">Heartfulness YouTube</a>
                                &nbsp;channel.
                            </div>
                            <div className="global-guide-form-title">Your message will be sent to a certified Heartfulness trainer who will connect with you for a 1-to-1 session.</div>

                        </>
                        :
                        <></>
                }
                <span>SEND MESSAGE</span>, your message will be sent after verification by Heartspots team

            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="trainer-form-input-box">
                    <FontAwesomeIcon icon={faUserCircle} className="user-circle-icon" />
                    <div className="trainer-form-input-field">
                        <input type='text' className="trainer-form-input"
                            name="name"
                            placeholder="Your Name"
                            aria-invalid={errors.name ? "true" : "false"} {...register('name', validators.name)} />
                        {errors.name && (<div role="alert" className="trainer-form-input-error"> {errors.name.message} </div>)}
                    </div>
                </div>
                <div className="trainer-form-input-box">
                    <FontAwesomeIcon icon={faEnvelope} className="user-circle-icon" />
                    <div className="trainer-form-input-field">
                        <input type="text" className="trainer-form-input"
                            placeholder="Email Id"
                            name="email"
                            aria-invalid={errors.email ? "true" : "false"} {...register('email', validators.email)} />
                        {errors.email && (<div role="alert" className="trainer-form-input-error"> {errors.email.message} </div>)}
                    </div>
                </div>
                <div className="trainer-form-input-box">
                    <FontAwesomeIcon icon={faPhoneAlt} className="user-circle-icon" />
                    <div className="trainer-form-input-field">
                        <Controller name="mobile" control={control} rules={validators.mobile} defaultValue='' render={props => {
                            return <PhoneInput
                                className="trainer-form-input"
                                placeholder="Your Phone No."
                                defaultCountry="IN"
                                aria-invalid={errors.mobile ? "true" : "false"}
                                value={props.field.value}
                                name={props.field.name}
                                onChange={props.field.onChange}
                            />
                        }}
                        />
                        {errors.mobile && (<div role="alert" className="trainer-form-input-error"> {errors.mobile.message} </div>)}
                    </div>
                </div>
                <div className="trainer-form-input-box">
                    <FontAwesomeIcon icon={faGlobe} className="user-circle-icon" />
                    <div className="trainer-form-input-field">
                        <input type="text" className="trainer-form-input"
                            placeholder="Location"
                            name="location"
                            aria-invalid={errors.location ? "true" : "false"} {...register('location', validators.location)} />
                        {errors.location && (<div role="alert" className="trainer-form-input-error"> {errors.location.message} </div>)}
                    </div>
                </div>
                <div className="trainer-form-input-box">
                    <FontAwesomeIcon icon={faEnvelopeSquare} className="user-circle-icon" />
                    <div className="trainer-form-input-field">
                        <textarea rows={8} type="text"
                            className="trainer-form-input trainer-form-message-input"
                            name="message"
                            placeholder="Your Message"
                            aria-invalid={errors.message ? "true" : "false"} {...register('message', validators.message)} />
                        {errors.message && (<div role="alert" className="trainer-form-input-error"> {errors.message.message} </div>)}
                    </div>
                </div>
                <div className="trainer-form-checkbox-box">
                    <label>
                        <input type="checkbox" className="trainer-form-checkbox" name="terms" aria-invalid={errors.terms ? "true" : "false"} {...register('terms', validators.terms)} />
                        <span className="trainer-form-checkbox-label">
                            I Agree to the&nbsp;
                            <a href={config.heartfulnessTermsURL} rel="noreferrer" target="_blank">Terms and Conditions</a>
                        </span>
                    </label>
                    {errors.terms && (<div role="alert" className="trainer-form-input-terms-error"> {errors.terms.message} </div>)}
                </div>
                <div className="trainer-form-submit-box">
                    <button className="trainer-form-submit-button" type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ContactForm;
