import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import {
  setLoader,
  clearSearch,
  setResults,
} from "../../store/actions/actions";
import TrainersService from "../../services/trainersService";
import CentersService from "../../services/centersService";

const searchDefaults = {
  trainersLimit: 100,
  centersLimit: 50,
  distanceUnit: "KM",
  minDistance: 5,
};

const trainersService = new TrainersService();
const centersService = new CentersService();

const SearchBar = (props) => {
  const [location, setLocation] = useState("");
  const [autocomplete, setAutoComplete] = useState();
  const [geometry, setGeometry] = useState(null);
  const [distance, setDistance] = useState(searchDefaults.minDistance);
  const [distanceUnit, setDistanceUnit] = useState(searchDefaults.distanceUnit);
  const latestProps = useRef(props);
  const locationElement = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchLocations = (position) => {
      const place = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        place_id: "",
        formatted_address: "",
        distance: searchDefaults.minDistance,
        distanceUnit: searchDefaults.distanceUnit,
        limit: searchDefaults.trainersLimit,
        centersLimit: searchDefaults.centersLimit,
      };
      dispatch(setLoader(true));
      Promise.all([
        trainersService.getTrainers(place),
        centersService.getCenters(place),
      ])
        .then((res) => {
          setLocation(place.formatted_address);
          setGeometry({
            lat: place.latitude,
            lng: place.longitude,
            formatted_address: place.formatted_address,
            place_id: place.place_id,
          });
          dispatch(setResults(res[0].data, res[1].data, place));
          latestProps.current.onSearch(true);
        })
        .catch((err) => toast.error("Error. Something went wrong."))
        .then(() => dispatch(setLoader(false)));
    };

    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(searchLocations);
    }
  }, [dispatch, latestProps]);

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        const x = place.geometry.location.lat();
        const y = place.geometry.location.lng();
        if (place.formatted_address) setLocation(place.formatted_address);
        setGeometry({
          lat: x,
          lng: y,
          formatted_address: place.formatted_address,
          place_id: place.place_id,
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onSearch = () => {
    if (location === "" || !geometry) {
      locationElement.current.focus();
      return;
    }
    if (geometry.formatted_address !== location) {
      dispatch(clearSearch());
      locationElement.current.focus();
      return;
    }
    if (distance < 1) {
      toast.error("Please enter a valid distance");
      return;
    }

    const place = {
      latitude: geometry.lat,
      longitude: geometry.lng,
      place_id: geometry.place_id,
      formatted_address: geometry.formatted_address,
      distance: distance,
      distanceUnit: distanceUnit,
      limit: searchDefaults.trainersLimit,
      centersLimit: searchDefaults.centersLimit,
    };
    dispatch(setLoader(true));

    Promise.all([
      trainersService.getTrainers(place),
      centersService.getCenters(place),
    ])
      .then((res) => {
        dispatch(setResults(res[0].data, res[1].data, place));
      })
      .catch((err) => {
        dispatch(clearSearch());
        toast.error("Error. Something went wrong.");
      })
      .then(() => dispatch(setLoader(false)));
    props.onSearch(true);
  };

  const onClear = () => {
    props.onSearch(false);
    setLocation("");
    setGeometry(null);
    setDistance(searchDefaults.minDistance);
    dispatch(clearSearch());
  };

  return (
    <>
      <h2 className="header-HeartSpot">
        Heartsp<span className="header-HeartSpot-Span">o</span>ts
      </h2>
      <div className="searchBar-Box">
        <div className="searchStrip-FlexBox">
          <div className="searchLocation-FlexBox">
            <label className="searchLocation-Label">LOCATION</label>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} fields ={['place_id', 'formatted_address','geometry.location']}>
              <input
                name="heartspots-location"
                placeholder="Enter Pin-code, Area, City, Town or Country!"
                type="text"
                value={location}
                className="searchInput-TextBox"
                ref={locationElement}
                onChange={(e) => setLocation(e.target.value)}
                autoComplete="false"
              ></input>
            </Autocomplete>
          </div>
          <div className="searchDistance-FlexBox">
            <div className="searchWithin-FlexBox">
              <label className="searchWithin-Label">WITHIN</label>
              <input
                name="heartspots-miles-text"
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                type="number"
                className="search-miles-input-textbox"
                min={5}
                max={10000}
              ></input>
            </div>
            <select
              name="distanceUnit"
              value={distanceUnit}
              onChange={(e) => setDistanceUnit(e.target.value)}
              className="search-distance-unit-box"
              required
            >
              <option value="MILES">Miles</option>
              <option value="KM">KMs</option>
            </select>
            <div className="searchSlider-Box">
              <input
                type="range"
                min={5}
                max={100}
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                className="searchInput-Slider"
              />
            </div>
          </div>
          <div className="searchButton-FlexBox">
            <div className="search-button">
              <button
                className="searchButton searchButton-search"
                onClick={onSearch}
              >
                {" "}
                SEARCH{" "}
              </button>
            </div>
            <div className="clear-button">
              <button
                className="searchButton searchButton-Clear"
                onClick={onClear}
              >
                {" "}
                CLEAR{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
