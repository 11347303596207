import { useSelector, useDispatch } from "react-redux";
import { setActiveCenterTab } from "../../store/actions/actions";

const SearchResultTabs = (deepLink) => {
  const linkValues = { ...deepLink.deepLink };

  const [isActiveCenterTab, totalTrainerCount, totalCenterCount] = useSelector(
    (state) => [
      state.home.isActiveCenterTab,
      state.home.trainerResults.totalCount,
      state.home.centerResults.totalCount,
    ]
  );
  

  const dispatch = useDispatch();
  const onTabClick = (value) => {
    dispatch(setActiveCenterTab(value));
  };
  return (
    <>
      {linkValues.path === "/deep" ? (
        <div className="trainersAndCentersTabs-Section">
          <ul className="trainersAndCentersTabs-List">
            <li
              className="trainersAndCentersTab-ListItem"
              onClick={() => onTabClick(false)}
            >
              {linkValues.tab === "trainer" && (
                <div className="trainersAndCenters-Tab">
                  {isActiveCenterTab ? (
                    <h1 className="trainersAndCentersTab-Text">
                      Trainers (<span> {totalTrainerCount} </span>)
                    </h1>
                  ) : (
                    <h1 className="trainersAndCentersTab-Text trainersAndCentersTab-Active">
                      Trainers (<span> {totalTrainerCount} </span>)
                    </h1>
                  )}
                </div>
              )}
            </li>
            <li
              className="trainersAndCentersTab-ListItem"
              onClick={() => onTabClick(true)}
            >
              {linkValues.tab === "center" && (
                <div className="trainersAndCenters-Tab">
                  {isActiveCenterTab ? (
                    <h1 className="trainersAndCentersTab-Text trainersAndCentersTab-Active">
                      Meditation Centers (<span> {totalCenterCount} </span>)
                    </h1>
                  ) : (
                    <h1 className="trainersAndCentersTab-Text">
                      Meditation Centers (<span> {totalCenterCount} </span>)
                    </h1>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      ) : (
        <div className="trainersAndCentersTabs-Section">
          <ul className="trainersAndCentersTabs-List">
            <li
              className="trainersAndCentersTab-ListItem"
              onClick={() => onTabClick(false)}
            >
              <div className="trainersAndCenters-Tab">
                {isActiveCenterTab ? (
                  <h1 className="trainersAndCentersTab-Text">
                    Trainers (<span> {totalTrainerCount} </span>)
                  </h1>
                ) : (
                  <h1 className="trainersAndCentersTab-Text trainersAndCentersTab-Active">
                    Trainers (<span> {totalTrainerCount} </span>)
                  </h1>
                )}
              </div>
            </li>
            <li
              className="trainersAndCentersTab-ListItem"
              onClick={() => onTabClick(true)}
            >
              <div className="trainersAndCenters-Tab">
                {isActiveCenterTab ? (
                  <h1 className="trainersAndCentersTab-Text trainersAndCentersTab-Active">
                    Meditation Centers (<span> {totalCenterCount} </span>)
                  </h1>
                ) : (
                  <h1 className="trainersAndCentersTab-Text">
                    Meditation Centers (<span> {totalCenterCount} </span>)
                  </h1>
                )}
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchResultTabs;
