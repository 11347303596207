const Footer = () => {
    return (
        <div className="footer-section">
            <div className="footer-bottom-bar">
                <div className="footer-bottom-box">
                    <div className="footer-bottom-flex-box">
                        <div className="footer-bottom-float-left">
					        © Copyright 2021 Sahaj Marg Spirituality Foundation, All Rights Reserved
                        </div>            
                    </div>            
                </div>
            </div>
        </div>
    )
}

export default Footer;