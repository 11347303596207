import React, { useState, useEffect, useRef, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";

// state
import { useDispatch } from "react-redux";

import { setLoader, clearSearch, setResults } from "../../../store/actions/actions";

// map
import { Autocomplete } from "@react-google-maps/api";

// toast
import { toast } from "react-toastify";

// services
import TrainerService from "../../../services/trainersService";

import CenterService from "../../../services/centersService";

// constants
const searchDefaults = {
  trainersLimit: 100,
  centersLimit: 50,
  distanceUnit: "KM",
  minDistance: 25
};

const SearchBar = forwardRef((props, ref) => {
  const [location, setLocation] = useState("");

  const [autocomplete, setAutoComplete] = useState(null);

  const [geometry, setGeometry] = useState(null);

  const [distance, setDistance] = useState(searchDefaults.minDistance);

  const [distanceUnit, setDistanceUnit] = useState(searchDefaults.distanceUnit);

  const locationRef = useRef(null);

  const trainerService = useMemo(() => new TrainerService(), []);

  const centerService = useMemo(() => new CenterService(), []);

  const dispatch = useDispatch();

  useEffect(() => {
    const searchLocations = position => {
      const place = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        place_id: "",
        formatted_address: "",
        distance: searchDefaults.minDistance,
        distanceUnit: searchDefaults.distanceUnit,
        limit: searchDefaults.trainersLimit,
        centersLimit: searchDefaults.centersLimit
      };

      dispatch(setLoader(true));

      Promise.all([trainerService.getTrainers(place), centerService.getCenters(place)])
        .then(res => {
          setLocation(place.formatted_address);
          setGeometry({
            lat: place.latitude,
            lng: place.longitude,
            formatted_address: place.formatted_address,
            place_id: place.place_id
          })
          dispatch(setResults(res[0].data, res[1].data, place));
        })
        .catch(() => toast.error("Error. Something went wrong. Make sure you have proper internet connection"))
        .then(() => dispatch(setLoader(false)));
    }

    if (navigator && navigator.geolocation && typeof navigator.geolocation.getCurrentPosition === "function")
      navigator.geolocation.getCurrentPosition(searchLocations);
  }, [dispatch, trainerService, centerService]);

  const onLoad = useCallback(autocomplete => {
    setAutoComplete(autocomplete);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        const x = place.geometry.location.lat();
        const y = place.geometry.location.lng();
        if (place.formatted_address)
          setLocation(place.formatted_address);
        setGeometry({ lat: x, lng: y, formatted_address: place.formatted_address, place_id: place.place_id });
      }
    }
    else
      console.log("Autocomplete is not loaded yet!");
  }, [autocomplete]);

  const onSearch = useCallback(() => {
    if (location === "" || !geometry) {
      locationRef.current.focus();
      return;
    }

    if (geometry.formatted_address !== location) {
      dispatch(clearSearch())
      locationRef.current.focus();
      return;
    }

    if (Number.isSafeInteger(distance) && distance < 1) {
      toast.error("Please enter a valid distance");
      return;
    }

    const place = {
      latitude: geometry.lat,
      longitude: geometry.lng,
      place_id: geometry.place_id,
      formatted_address: geometry.formatted_address,
      distance: distance,
      distanceUnit: distanceUnit,
      limit: searchDefaults.trainersLimit,
      centersLimit: searchDefaults.centersLimit
    };

    dispatch(setLoader(true));

    Promise.all([trainerService.getTrainers(place), centerService.getCenters(place)])
      .then(res => dispatch(setResults(res[0].data, res[1].data, place)))
      .catch(() => {
        dispatch(clearSearch());
        toast.error("Error. Something went wrong. Make sure you have proper internet connection");
      })
      .then(() => dispatch(setLoader(false)));
  }, [trainerService, centerService, dispatch, distance, distanceUnit, geometry, location]);

  const onClear = useCallback(() => {
    setLocation("");
    setGeometry(null);
    setDistance(searchDefaults.minDistance);
    setDistanceUnit(searchDefaults.distanceUnit);
    dispatch(clearSearch());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    clear: onClear
  }), [onClear]);

  return <>
    <div className="section-box">
      <div className="section-flex">
        <div className="header"> Search Your Nearest Trainer and Center</div>
        <div className="location">
          <label className="input-label">LOCATION</label>
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            fields ={['place_id', 'formatted_address','geometry.location']}
            >
            <input
              className="width-full input-text"
              type="text"
              name="heartspots-location"
              placeholder="Enter Area, City, Town or Country!"
              autoComplete="off"
              value={location}
              onChange={e => setLocation(e.target.value)}
              ref={locationRef}
            >
            </input>
          </Autocomplete>
        </div>
        <div className="distance">
          <label className="input-label">WITHIN</label>
          <div className="distance-input">
            <input name="heartspots-distance" value={distance}
              onChange={e => setDistance(e.target.value)} type="number"
              className="width-full input-text" min={5} max={10000}></input>
            <button className={`width-full distance-button button ${distanceUnit !== "KM" ? "in-active" : ""}`} onClick={() => setDistanceUnit("KMS")}>
              KMS
            </button>
            <button className={`width-full distance-button button ${distanceUnit !== "MILES" ? "in-active" : ""}`} onClick={() => setDistanceUnit("MILES")}>
              MILES
            </button>
          </div>
          <div className="width-full slider-box">
            <input type="range" min={5} max={100}
              value={distance}
              onChange={e => setDistance(e.target.value)}
              className="width-full distance-slider" />
          </div>
        </div>
        <button type="button" className="width-full search-btn button" onClick={onSearch}> SEARCH </button>
      </div>
    </div>
  </>
});

export default SearchBar;
