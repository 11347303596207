// route
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// captcha
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// components
import { ToastContainer } from "react-toastify";

import Loader from "./components/loader/Loader";

import Home from "./components/pages/home";

import MobileView from "./components/pages/mobileView";

// config
import config from "./assets/data/site-config";
import DeepLinkHome from "./components/pages/deepLink";
import MobileViewDeepIndex from "./components/pages/mobileDeepLink";

const App = () => {
  const screenWidth = (window && window.screen) ? window.screen.width : null;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.captchaKey}>
      <Router>
        <Routes>
          <Route path="/" element={(screenWidth && screenWidth < 768) ? <MobileView /> : <Home />} />

          <Route path="/m" element={<MobileView />} />
          <Route path="/deep" element={<DeepLinkHome />} />
          <Route path="/m-deep" element={<MobileViewDeepIndex />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>

      <Loader />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        theme="colored"
        icon={false}
      />
    </GoogleReCaptchaProvider>
  )
};

export default App;
