import React from "react";

// state
import { useSelector } from "react-redux";

// map
import { LoadScript } from "@react-google-maps/api";

// components
import SearchBar from "./SearchBar";

import SearchQueryBar from "./SearchQueryBar";

import SearchResultTabs from "./SearchResultTabs";

import TrainersResult from "./TrainersResult";

import CentersResult from "./CentersResult";

import Introduction from "./Introduction";

// config
import config from "../../../assets/data/site-config";

// constants
const MAP_LIBRARIES = ["places"];

const MobileView = () => {
  const [hasResults, isActiveCenterTab] = useSelector((state) => {
    return [
      state.home.trainerResults.totalCount > 0 ||
        state.home.centerResults.totalCount > 0,
      state.home.isActiveCenterTab,
    ];
  });
  return (
    <LoadScript
      libraries={MAP_LIBRARIES}
      googleMapsApiKey={config.googleMapsApiKey}
    >
      <div className="mv">
        <div className={`search-bar ${hasResults ? "display-none" : ""}`}>
          <SearchBar />
        </div>
        {hasResults ? (
          <div className="search-info-section">
            <SearchQueryBar />
            <SearchResultTabs />
          </div>
        ) : null}
        {hasResults ? (
          <div className="result">
            <div className="section">
              {isActiveCenterTab ? <CentersResult /> : <TrainersResult />}
            </div>
          </div>
        ) : null}
        <Introduction showTrainersAndCenters={true} />
      </div>
    </LoadScript>
  );
};

export default MobileView;
